@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: -apple-system, BlinkMacSystemFont, 'Permanent Marker', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* For Webkit Browsers */
html {
  scrollbar-width: thin;
  scrollbar-color: #0284c7 #1e293b; /* sky-600 and slate-950 color codes */
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #1e293b; /* slate-950 color code */
}

body::-webkit-scrollbar-thumb {
  background-color: #0284c7; /* sky-600 color code */
  border-radius: 6px;
  border: 3px solid #1e293b; /* creating padding around scroll thumb */
}

/* For Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: #0284c7 #1e293b; /* thumb and track color */
}




/* customStyles.css */
.Toastify__toast-container {
  z-index: 100 !important;
}
